.news-item {
  position: relative;
  z-index: 1;
  
  &--image {
    overflow: hidden;
    img {
      @include transition(all, 0.2s, ease-in-out);
    }
  }

  &--content {
    @include transition(all, 0.1s, ease-in-out);
  }
      
  &--link {
    display: block;
    &:hover {
      .news-item--content {
        transform: translateY(-5px);
      }
      .news-item--image {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}