#footer-top {
	background: map.get($colors, 'blue-regular');
	padding: 12.5px 0;
	clear: both;

	.link {
		align-items: center;
		color: $white;
		display: flex;
		text-transform: uppercase;
		@include font-size(13);

		.txt,
		.sprite {
			@include transition(all, 0.2s, ease-in-out);
		}

		.sprite {
			position: relative;

			&:after {
				content: "";
				background-color: $white;
				height: calc(100% - 5px);
				left: 40px;
				position: absolute;
				top: 2.5px;
				width: 1px;
			}
		}

		@include media-breakpoint-up(sm) {
			&:hover,
			&:focus {
				.sprite {
					transform: translateX(2.5px);
				}
				.txt {
					opacity: .6;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			justify-content: center;
			display: block;
			.sprite {
				display: block;
				margin: 0 auto;
				&:after {
					display: none;
				}
			}
		}

	}

	@include media-breakpoint-down(md) {
		margin-top: 50px;
	}
}

#footer {
	background-color: map.get($colors, 'blue-dark');
	background-image: url('assets/images/footer-bg.png');
	background-repeat: no-repeat;
	background-position: 27.5% 0px;
	color: $white;
	padding: 40px 0;
	position: relative;

	@include media-breakpoint-down(lg) {
		background-image: none !important;
        background: map.get($colors, 'blue-dark')!important; //for safari wave still display
	}

	.link-social {
		display: block;
		.sprite {
			display: block;
			@include transition(all, 0.2s, ease-in-out);
		}

		&:hover,
		&:focus {
			.sprite {
				transform: scale(.9);
			}
		}
	}

	.list {
		li {
			&:not(:last-child) {
				margin-bottom: 5px;
			}
			a {
				position: relative;
				display: block;
				&:before {
					content:"";
					position: absolute;
					left: -35px;
					opacity: 0;
					top: 2px;
					@extend .sprite;
					@extend .arrow-more-white;
					@include transition(opacity, 0.2s, ease-in-out);
					@include media-breakpoint-down(sm) {
						transform: scale(.7) translateX(10px);
					}
					@include media-breakpoint-down(sm) {
						display: none;
					}
				}
				&:focus,
				&:hover {
					color: map.get($colors, 'blue-xlight');
					&:before {
						opacity: 1;
					}
				}
			}
		}
	}

	.title {
		font-size: 1rem;
		text-transform: uppercase;
	}

	.link-button {
		@extend .button-regular;
		background: transparent;
		border-color: $white;
		color: $white;
		padding: 3px 50px 3px 50px;
		@include font-size(16);

    margin: 0 auto;
    display: block;
    width: 253px;

		> * {
			vertical-align: middle;
		}

    &:hover,
    &:focus {
      .sprite {
        background-position: -240px -35px;
      }
    }
	}

  .footer-collectivites {
    background: white;
    border-radius: 30px;
    width: 66%;
    margin-left: auto;
    margin-right: auto;
  }


	@include media-breakpoint-down(lg) {}

	@include media-breakpoint-down(md) {}

	@include media-breakpoint-down(sm) {
		padding: 25px 0;
		text-align: center;
	}
}

#button-chat-bot {
	position: fixed;
	bottom: 30px;
	right: 30px;

	span:last-child {
		border-radius: 3px;
		margin-top: 3px;
	}

	.sprite {
		@include transition(all, 0.1s, ease-in-out);
	}

	&:hover {
		.sprite {
			transform: scale(.95);
		}
	}
	> * {
		display: block;
	}
}

#button-chat-bot{
  cursor: pointer;
  display: none;
  z-index: 2;
  /*span:first-child {
    display: none;
  }*/
  @include media-breakpoint-down(sm) {
    right: 10px;
    .sprite.chat-bot{
      margin-left: 10px;
    }
  }
}

#button-chat-bot .color-grey-regular{
  background-color: white;
}
#talkr-container {
  position: fixed;
  bottom: 58px;
  right: 52px;
  visibility: hidden;
  display: none;
  z-index: 2147483632;

  span:last-child {
    border-radius: 3px;
    margin-top: 3px;
  }

  .sprite {
    @include transition(all, 0.1s, ease-in-out);
  }
  .kwalys-open-chatbox-button{
    position: fixed;
    bottom: 58px;
    right: 44px;
    width: 70px;
    height: 70px;
    background: transparent!important;
    --before-border-color: transparent!important;
    --after-border-color: transparent!important;
    border: none;
    visibility: hidden;

    img{
      width: 70px;
      height: 70px;
      background-position: -360px -40px;
      background-image: url('assets/icons/sprite.png');
      background-repeat: no-repeat;
      display: inline-block;
      vertical-align: text-top;
      &:hover {
        transform: scale(.95);
      }
    }
    &::before {
    content: none!important;
    display: none!important;
    }

    &::after {
      content: none!important;
      display: none!important;
    }

    /*> * {
      display: block;
    }*/
  }
}
