.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	position: absolute;
	bottom: 15px;
	width: 100%;
	text-align: center;

	li {
		cursor: pointer;
		display: inline-block;
		vertical-align: top;
		margin: 0 5px;
		position: relative;
		line-height: 0em;

		&:after {
			content: "";
			opacity: 0;
			border-radius: 100%;
			border: 1px solid $white;
			left: -5px;
			height: 18px;
			position: absolute;
			top: -5px;
			width: 18px;
		}
	}

	button {
		width: 8px;
		height: 8px;
		border-radius: 100%;
		margin-right: 5px;
		background-color: $white;
		border-radius: px(18);
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active {
		button {
			background-color: map.get($colors, 'blue-light');
			&:focus,
			&:hover {
				background-color: map.get($colors, 'blue-light');
			}
		}

		&:after {
			opacity: 1;
		}
	}


	@include media-breakpoint-down(sm) {
		bottom: auto;
		padding: 20px 0 8px;
		position: relative;

		li {
			&.slick-active {
				&:after {
					border-color: map.get($colors, 'blue-light');
					opacity: 1;
				}
			}
		}

		button {
			background-color: color.scale(map.get($colors, 'grey-regular'), $lightness: 50%)
		}
	}
}


#slick-home-wrapper {
	.item {
		align-items: flex-end;
		display: flex;
		justify-content: flex-end;
		padding: 0 0 50px 0;
		height: 400px;
		position: relative;
		background-size: cover;
		background-position: top center;

		.content {
			background-color: color.adjust(map.get($colors, 'blue-dark'), $alpha: -0.1);
			color: $white;
			padding: 35px 40px 55px 40px;
			position: relative;
			float: right;
			width: 480px;
			@include transition(all, 0.2s, ease-in-out);

            &.has-link {

                &:hover {
                    background-color: color.adjust(map.get($colors, 'blue-dark'), $alpha: -0.2);
                    .link:after {
                        right: 45px;
                    }
                }

                .link {
                    color: white;

                    &:after {
                        content: "";
                        @extend .sprite;
                        @extend .arrow-more-white;
                        bottom: 25px;
                        position: absolute;
                        right: 35px;
                        @include transition(all, 0.2s, ease-in-out);
                    }

                }
            }


		}



	}

	@include media-breakpoint-down(sm) {
		.item {
			height: 300px;
			padding-bottom: 0;

			.container {
				padding-left: 0;
				padding-right: 0;
			}

			.content {
				opacity: 0;
				padding: 25px 25px 45px;
				width: 100%;
				float: none;
				margin-left: 0;
			}
		}
	}

	.slick-slide .content {
		opacity: 0;
		transform:  scale(.8);
		transition: all 0.1s ease-in-out;
	}

	.slick-slide.animation .content {
		opacity: 1;
		transform: translateY(0) scale(1);
	}

}
