@font-face {
  font-family: 'roboto_condensedbold';
  src: url('fonts/RobotoCondensed-Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensedregular';
  src: url('fonts/RobotoCondensed-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensedlight';
  src: url('fonts/RobotoCondensed-Light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$font-bold: 'roboto_condensedbold', sans-serif;
$font-regular: 'roboto_condensedregular', sans-serif;
$font-light: 'roboto_condensedlight', sans-serif;

$fonts: (
  (title-1, 48, normal, $font-bold, 1.2, title),
  (title-2, 42, normal, $font-light, 1.2, title),
  (title-3, 34, normal, $font-bold, 1.2, title),
  (title-4, 34, normal, $font-light, 1.2, title),
  (title-5, 26, normal, $font-bold, 1.2, title),
  (title-6, 26, normal, $font-bold, 1.2, title),
  (title-7, 22, normal, $font-bold, 1.2, title),
  (title-8, 18, normal, $font-bold, 1.2, title),
  (text-1, 18, normal, $font-regular, 1.4, text),
  (text-2, 15, normal, $font-regular, 1.2, text),
  (text-3, 15, normal, $font-bold, 1.2, text),
  (text-4, 15, normal, $font-light, 1.2, text),
  (text-5, 13, normal, $font-bold, 1.2, text),
  (text-6, 13, italic, $font-regular, 1.2, text)
);

html {
  color: $black;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-regular;
}

strong {
  font-family: $font-bold;
}

@each
  $label,
  $font-size,
  $font-style,
  $font-family,
  $line-height,
  $type
  in $fonts {
  .#{$label} {
    line-height: $line-height;
    font-family: $font-family;
    font-style: $font-style;
    @include font-size($font-size);

    @if $label == 'title-6' {
      text-transform: uppercase;
    }

    @if $type == title {
      @include media-breakpoint-down(md) {
        @include font-size(calc(math.div($font-size, 1.1)));
      }
    }

    @include media-breakpoint-down(sm) {
      @if type == text and size > 15 {
        @include font-size(calc(math.div($font-size,1.2)));
      }
    }
  }
}
