.form-control {
  border-color: $gray-400;
	border-width: 3px;
	color: map.get($colors, 'blue-dark') !important;
	padding: .5rem .5rem;

	&::placeholder {
		color: #999;
		opacity: 1;
		@include font-size(15);
  }
}

.file-picker {
  display: inline-block;
	height: calc(2.25rem + 2px);
	margin-bottom: 0;
	position: relative;
	width: 100%;

	&--input {
    height: calc(2.25rem + 2px);
		margin: 0;
		opacity: 0;
		position: relative;
		width: 100%;
		z-index: 2;
	}

	&--label {
    height: calc(2.25rem + 2px);
    left: 0;
		position: absolute;
    top: 0;
    right: 0;
		z-index: 1;
		@extend .form-control;

		&::after {
			align-items: center;
			background-color: $black;
			bottom: 0;
			color: $white;
			display: flex;
			justify-content: center;
			height: calc(calc(2.25rem + 2px) - 1px * 2);
			padding: 0 15px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 3;
		}
	}

	&--input:lang(fr) ~ .file-picker--label {
    &::after {
      content: "Rechercher";
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
	border: none !important;
	-webkit-text-fill-color: inherit !important;
	-webkit-box-shadow: 0 0 0 1000px #FFFFFF inset;
	transition: background-color 5000s ease-in-out 0s;
}

.form-check {
	align-items: center;
	display: flex;

	label {
		color: map.get($colors, 'grey-regular');
		padding-left: 10px;
		line-height: 1.2em;
	}

	&.size-lg {
		line-height: 4em;
		margin-bottom: 0;

		input {
			height: 3.5em;
			width: 3.5em;
			min-width: 3.5em;
		}

		label {
			@include font-size(18);
		}
	}

	&.size-md {
		line-height: 2em;
		margin-bottom: 10px;

		input {
			height: 2em;
			width: 2em;
			min-width: 2em;
		}

		label {
			padding-top: 4px;
			@include font-size(16);
		}

	}

	.form-check-input {
		border-width: 3px;

		&:checked {
			background-color: map.get($colors, 'blue-light');
			border-color: map.get($colors, 'blue-light');
			+ label {
				color: map.get($colors, 'blue-light');
			}
		}
	}
}


.select2-container {
	text-align: left;

	.select2-results__option--highlighted {
		&.select2-results__option--highlighted {
			background-color: map.get($colors, 'blue-light');
		}
	}

	&.select2-container--default {
		.select2-selection--single {
			height: 40px;
			border-width: 3px;
			border-color: $gray-300;

			.select2-selection__rendered {
				line-height: 36px;
				color: map.get($colors, 'blue-dark');
			}

			.select2-selection__arrow {
				top: 8px;
				right: 5px;
			}
		}
	}
}

.selectpicker-alert + .select2-container--default {

	&.select2-container--default {
		width: 100% !important;

		.select2-selection--multiple {
			min-height: 44px;
			border-width: 3px;
			border-color: $gray-400;
			padding-right: 45px;
		}

		.select2-selection__choice {
			margin-top: 7px;
		}
		.select2-search {
			min-height: 44px;


			.select2-search__field {
				margin-top: 8px;
				height: 20px;
				font-family: $font-regular;

				&::placeholder {
					opacity: 1;
					@include font-size(15);
				}
			}
		}
	}
}

.selectpicker-alert-wrapper {
	position: relative;

	.material-icons {
		color: $gray-500;
		position: absolute;
		top: 7px;
		right: 0;
		z-index: 1;
		@include font-size(30);
	}
}

.form-helper {
	align-items: center;
	display: flex;
	margin-top: 10px;

	.material-icons {
		color: map.get($colors, 'grey-regular');
		font-size: 18px;
		margin-right: 5px;
	}
}

.ui-button.ui-state-active:hover,
.ui-button:active,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-state-default.ui-state-active.ui-state-hover {
	border: none;
	background: map.get($colors, 'blue-dark') !important;
}

.form-overlay {
	background-color: map.get($colors, 'grey-light');
	position: relative;

	&.activated {
		&:after {
			content: "";
			cursor: not-allowed;
			background-color: $white;
			height: 100%;
			left: 0;
			opacity: .5;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	.container {
		margin-top: -20px;
		padding: 10px 70px 60px;
	}

	@include media-breakpoint-down(md) {
		.container {
			margin-top: 30px;
			padding: 5px 15px 30px;
		}
	}
}

.form-category-item {
	align-items: center;
	background-color: map.get($colors, 'grey-light');
	display: flex;
	height: 100%;
	padding: 20px;

	.material-icons {
		background-color: #fff;
		border-radius: 100%;
		color: map.get($colors, 'blue-dark');
		padding: 15px;
		@include transition(all, 0.2s, ease-in-out);
		@include font-size(36);
	}

	&--title {
		color: map.get($colors, 'blue-dark');
		font-family: $font-bold;
		line-height: 1.3em;
		padding-left: 20px;
		@include transition(all, 0.1s, ease-in-out);
		@include font-size(19);
	}

	&:hover {
		.material-icons {
			color: map.get($colors, 'blue-light');
			transform: scale(1.1);
		}
		.form-category-item--title {
			color: map.get($colors, 'blue-light');

		}
	}
}

.compteur-digit{
    display: block;
    box-sizing: border-box;
    float:left;
    color: white;
    font-family: 'Courier New', 'Lucida Console', 'Consolas',  monospace;
    font-weight: 900;
    font-size: 2em;
    text-shadow: 0 2px 3px rgba(0,0,0,0.7);
    padding: 0.3em 0 0;
    margin: 0 0.1em 0 0;
    background-color: #444;
    max-width: 1em;
    max-height: 1.8em;
    width: 1em;
    height: 1.8em;
    z-index: 1;
    line-height: 1.4em;
    text-align: center;
    overflow: hidden;
    word-break: break-all;
    -moz-box-shadow:    inset  0  1em 1em -1em #000,
    inset  0 -1em 1em -1em #000;
    -webkit-box-shadow: inset  0  1em 1em -1em #000,
    inset  0 -1em 1em -1em #000;
    box-shadow:        inset  0  1em 1em -1em #000,
    inset  0 -1em 1em -1em #000;
}

.digit-ignore{
    background: red;
}
#compteur-form{
    .parsley-errors-list{
        margin-top: -21px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
