$sprites : (
  (scroll-top, 30px, 30px, 0 0),
  (info-disturbance, 30px, 30px, -40px 0),
  (info-alert, 30px, 30px, -70px 0),
  (info-leak, 30px, 30px, -100px 0),
  (info-disturbance-yellow, 30px, 30px, -40px -30px),
  (info-alert-yellow, 30px, 30px, -70px -30px),
  (info-leak-yellow, 30px, 30px, -100px -30px),
  (social-instagram, 30px, 30px, -160px 0),
  (social-facebook, 30px, 30px, -190px 0),
  (social-youtube, 30px, 30px, -220px 0),
  (footer-contact, 30px, 30px, -280px 0),
  (footer-informations, 30px, 30px, -310px 0 ),
  (footer-contact-button, 30px, 30px, -340px 0),
  (footer-credit-card, 30px, 30px, -370px 0),
  (user-account-black, 30px, 30px, -400px 0),
  (user-account-white, 30px, 30px, -430px 0),
  (arrow-bottom-blue, 20px, 10px, -160px -40px),
  (arrow-bottom-white, 20px, 10px, -160px -50px),
  (arrow-more-blue-dark, 30px, 20px, -180px -40px),
  (arrow-more-blue-light, 30px, 20px, -210px -40px),
  (arrow-more-white, 30px, 20px, -240px -40px),
  (arrow-large, 90px, 70px, -270px -40px),
  (chat-bot, 70px, 70px, -360px -40px),
  (report-interventions, 37px, 37px, -40px -80px),
  (report-resources, 37px, 37px, -90px -80px),
  (report-situation, 37px, 37px, -140px -80px),
  (report-full, 110px, 110px, -40px -140px),
  (icon-network, 90px, 90px, -180px -140px),
  (icon-report, 90px, 90px, -290px -140px),
  (icon-zapay, 80px, 80px, -400px -140px),
  (icon-meet, 80px, 80px, -480px -140px),
  (icon-proxeo, 80px, 80px, -560px -140px),
);

.sprite {
	background-image: url('assets/icons/sprite.png');
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: text-top;

  @each $label, $width, $height, $background-position in $sprites {
    &.#{$label} {
      width: $width;
      height: $height;
      background-position: $background-position;
    }
  }
}