.wysiwyg-wrapper {
  padding-top: 50px;
  color: map.get($colors, 'grey-dark');
  >*:first-child {
    margin-top: 0 !important;
  }

  .btn-wrapper {
    margin: 15px 0;
    a {
      text-decoration: none;
      .sprite {
        @include transition(all, 0.2s, ease-in-out);
      }
    }
    &.regular {
      a {
        @extend .button-regular;
        &:before {
          content: "";
          @extend .sprite;
          @extend .arrow-more-blue-light;
          margin-right: 5px;
        }
        &:hover {
          &:before {
            @extend .arrow-more-white;
          }
        }
      }
    }
    &.alt {
      a {
        @extend .button-regular-alt;
        &:before {
          content: "";
          @extend .sprite;
          @extend .arrow-more-white;
          margin-right: 5px;
        }
        &:hover {
          &:before {
            @extend .arrow-more-blue-light;
          }
        }
      }
    }
  }

  >*:last-child {
    margin-bottom: 0 !important;
  }

  h1 {
    color: map.get($colors, 'blue-dark');
    @extend .title-1;
  }

  h2 {
    color: map.get($colors, 'blue-dark');
    @extend .title-3;
  }

  h3 {
    color: map.get($colors, 'blue-dark');
    @extend .title-5;
  }

  h4 {
    color: map.get($colors, 'blue-dark');
    @extend .title-8;
  }


  h1,
  h2,
  h3,
  h4 {
    color: map.get($colors, 'blue-dark');
    font-family: $font-bold;
  }

  .headline {
    font-size: 17px;
    font-weight: 600;
  }

  ol,
  ul {
    margin: 2rem 0;
  }

  ol li,
  ul li {
    display: block;
    position: relative;
    padding-left: 50px;
    margin-bottom: 10px;
  }

  ul li {
    font-weight: 400;
    @include font-size(16);
  }

  ul li:before {
    content: "";
    position: absolute;
    top: 7px;
    border-radius: 100%;
    left: 30px;
    width: 8px;
    height: 8px;
    border: 2px solid map.get($colors, 'blue-light');
  }

  ol {
    counter-reset: ol-counter;
  }

  ol li {
    position: relative;
    display: block;
    line-height: 1.4em;
    @include font-size(16);
    font-weight: 400;
  }

  ol li:before {
    content: counter(ol-counter)".";
    color: map.get($colors, 'blue-light');
    counter-increment: ol-counter;
    font-weight: 600;
    position: absolute;
    font-family: $font-bold;
    top: 0;
    left: 30px;
    @include font-size(14);
  }

  ol li:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    &:not(.accordion-header){
      margin: 3rem 0 1rem;
    }
  }

  p {
    @include font-size(16);
    margin: 2rem 0;
  }

  b, strong {
    font-weight: normal;
  }

  a {
    color: map.get($colors, 'blue-light');
    text-decoration: underline;
  }

  .btn-wp {
    margin: 30px 0;
  }

  .btn-wp a {
    @extend .button-regular;
    text-decoration: none;
    padding-right: px(20);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  img.img-left {
    float: left;
    margin: 5px 30px 15px 0;
  }

  img.img-right {
    float: right;
    margin: 5px 0 15px 30px;
  }

  img.img-center {
    margin: 50px auto;
    display: block;
  }

  @include media-breakpoint-down(md) {
    padding-top: 0px;
    img {
      &.img-left,
      &.img-right {
        float: none;
        margin: 30px 0 !important;
        display: block;
      }
    }
  }
}


.wysiwyg-higlight {
  margin: 50px 0;
  padding: 50px 200px;
  position: relative;
  @include font-size(16);
  color: $black;

  .title {
    font-family: $font-bold;
    margin-bottom: 10px;
    color: $black;
    @include font-size(32);
    line-height: 1.05em;
  }

  &.type-yellow {
    background-color: map.get($colors, 'yellow');
  }

  &.type-orange {
    background-color: map.get($colors, 'orange');
  }

  &.type-blue-dark {
    background-color: map.get($colors, 'blue-dark');;
    color: $white;
    .title {
      color: $white;
    }
  }

  &.type-blue-regular {
    background-color: map.get($colors, 'blue-regular');
    color: $white;
    .title {
      color: $white;
    }
  }

  &.type-blue-light {
    background-color: map.get($colors, 'blue-light');
    color: $white;
    .title {
        color: $white;
    }
  }

  &.type-green {
    background-color: map.get($colors, 'green');
  }

  &:before,
  &:after {
    content: "";
    background-repeat: no-repeat;
    position: absolute;
    z-index: 2;
  }

  &:before {
		background-image: url('assets/images/wysiwyg-higlight-wave-left.png');
    height: 166px;
    top: 0;
    left: 0;
    width: 138px;
  }

  &:after {
		background-image: url('assets/images/wysiwyg-higlight-wave-right.png');
    bottom: 0;
    width: 197px;
    right: 0;
    height: 228px;
  }

  @include media-breakpoint-down(lg) {
    padding: 30px 30px 15px;

    &:after,
    &:before {
      display: none;
    }
  }
}

.wysiwyg-related-article  {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;

  &--image {
    overflow: hidden;
    max-width: 100%;
    img {
      @include transition(all, 0.2s, ease-in-out);
    }
  }

  &--title {
    background-color: map.get($colors, 'blue-light');
    bottom: -30px;
    color: $white;
    font-family: $font-bold;
    left: 30px;
    position: absolute;
    padding: 15px 20px;
    width: calc(100% - 60px);
    @include font-size(16);
  }

  &--link {
    display: block;
    &:hover {
      .wysiwyg-related-article--image{
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.accordion {
  margin-top: 50px;

  .accordion-header {
    margin-top: 2px;
  }

  .accordion-button {
    background-color: map.get($colors, 'blue-light');
    color: $white;
    display: block;
    line-height: 1.1em;
    padding: 10px 15px 10px 50px;
    text-align: left;
    position: relative;
    width: 100%;
    @include font-size(16);

    &:before {
      content: "-";
      color: $white;
      font-family: $font-light;
      position: absolute;
      left: 18px;
      top: 8px;
      @include font-size(32);
    }

    &.collapsed {
      background-color: map.get($colors, 'grey-light');
      color: map.get($colors, 'black');

      &:before {
        content: "+";
        color: map.get($colors, 'blue-light');
      }
    }
  }

  .accordion-body {
    background-color: map.get($colors, 'grey-light');
    color: map.get($colors, 'grey-regular');
    padding: 10px 20px 20px 50px;
    @include font-size(16);
  }
}

.wysiwyg-perturbation {

  >*:first-child {
    margin-top: 0 !important;
  }


  >*:last-child {
    margin-bottom: 0 !important;
  }

  p {
    margin: 0.7rem 0;
  }

  b, strong {
    font-weight: normal;
  }

  a {
    color: map.get($colors, 'blue-light');
    text-decoration: underline;
  }
}
