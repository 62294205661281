.label-network {
  align-items: center;
  background-color: $white;
  border-radius: 15px;
  display: flex;
  color: map.get($colors, 'grey-dark');
  line-height: 1em;
  padding: 5px 15px;

  .text {
    margin-left: 7.5px;
  }

  .material-icons {
    font-size: 18px;
  }
}

#inpt-offre-comm {
    visibility: hidden;
}
