.map-wrapper {
	position: relative;
	height: 300px;

	&.map-zapay {
		height: 450px;
		margin-top: -30px;
		@include media-breakpoint-down(lg) {
			margin-top: 10px;
			height: 300px;
		}
	}

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
