$buttons: (
  ( button-regular,
    inline-block,
    15,
    400,
    none,
    1.1,
    10px 15px,
    center,
    25px,
    map.get($colors, 'blue-light'),
    $white,
    1px solid map.get($colors, 'blue-light'),
    $white,
    map.get($colors, 'blue-light'),
    1px solid map.get($colors, 'blue-light')
  ),
  ( button-regular-alt,
    inline-block,
    15,
    400,
    none,
    1.1,
    10px 15px,
    center,
    25px,
    $white,
    map.get($colors, 'blue-light'),
    1px solid map.get($colors, 'blue-light'),
    map.get($colors, 'blue-light'),
    $white,
    1px solid map.get($colors, 'blue-light')
  ),
  ( button-more,
    inline-block,
    14,
    400,
    none,
    1.1,
    0,
    left,
    0,
    map.get($colors, 'blue-light'),
    transparent,
    none,
    map.get($colors, 'blue-light'),
    transparent,
    none,
  ),
);

@each $label, $display, $font-size, $font-weight, $text-transform, $line-height, $padding, $text-align, $border-radius, $color, $background-color, $border, $hover-color, $hover-background, $hover-border in $buttons {
  .#{$label} {
    background-color: $background-color;
    border: $border;
    border-radius: $border-radius;
    color: $color;
    display: $display;
    font-weight: $font-weight;
    line-height: $line-height;
    padding: $padding;
    text-align: $text-align;
    text-transform: $text-transform;
    @include font-size($font-size);
    @include transition(all, 0.2s, ease-in-out);

    .sprite {
      @include transition(all, 0.2s, ease-in-out);
    }

    &:hover,
    &:focus {
      background-color: $hover-background;
      color: $hover-color;
      border: $hover-border;

      @if $label == 'button-regular' {
        .sprite {
          background-position: -240px -40px;
        }
      }
      @if $label == 'button-regular-alt' {
        .sprite {
          background-position: -210px -40px;
        }
      }
      @if $label == 'button-more' {
        .sprite {
          transform: translateX(5px);
        }
      }


    }
  }
}

.button {
  &--mobile {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    height: 20px;
    position: relative;
    transform: rotate(0deg);
    width: 35px;
    z-index: 2;
    @include transition(all, 0.2s, ease-in-out);

    span {
      background: map.get($colors, 'white');
      display: block;
      height: 3px;
      left: 0;
      opacity: 1;
      position: absolute;
      transform: rotate(0deg);
      width: 100%;
      @include transition(all, 0.2s, ease-in-out);
    }

    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2),
    span:nth-child(3) {
      top: 9px;
    }

    span:nth-child(4) {
      top: 18px;
    }
  }

  &--scroll-top {
    position: absolute;
    bottom: 10px;
    right: 0;
    z-index: 99;

    @include media-breakpoint-down(sm) {
      bottom: 15px;
      right: 15px;
    }

    &:hover,
    &:focus {}
  }
}

.menu-open {
  .button--mobile {
    span:nth-child(1) {
      left: 50%;
      top: 18px;
      width: 0;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      left: 50%;
      top: 18px;
      width: 0;
    }
  }
}

.pagination {
  .page-item {
    .page-link {
      background-color: $white;
    }
    &:first-child,
    &:last-child {
      .page-link {
        background-color: map.get($colors, 'grey-light');
      }
    }
  }
}
