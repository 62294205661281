.parsley-errors-list {
	color: #a94442;
	margin-top: 5px;
	@include font-size(14);
}

.custom-file-input.parsley-error + .custom-file-label,
.form-control.parsley-error {
	border-color: map.get($colors, 'error');
	color: map.get($colors, 'error') !important;
}

.selectpicker.parsley-error + .select2 {
	.select2-selection {
		border-color:  map.get($colors, 'error') !important;
		.select2-selection__placeholder {
			color: map.get($colors, 'error') !important;
		}
		.select2-search__field {
			color: map.get($colors, 'error') !important;
		}
	}
}

.form-control.parsley-error::placeholder {
	color:map.get($colors, 'error');
}