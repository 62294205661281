#header {
	background-color: map.get($colors, 'white');
	position: relative;
	width: 100%;
	z-index: 10;
	@include transition(all, 0.2s, ease-in-out);

	@include media-breakpoint-down(sm) {
		.logo {
			width: 200px;
		}
	}
}

#button--mobile {
	border-radius: 5px;
	background-color: map.get($colors, 'blue-light');
	padding: 10px;
	position: absolute;
	top: 25px;
	right: 15px;
}

#navigation-main {
	align-items: center;
	display: flex;
	justify-content: flex-end;

	.item {
		@include transition(background, 0.2s, ease-in-out);
		&:not(:first-child) {
			margin-left: 15px;
		}
		&.focus {
			background-color: map.get($colors, 'blue-light-alpha');
			.link {
				color: map.get($colors, 'white');
				.arrow-bottom-blue {
					background-position: -160px -50px;
				}
				em {
					color: map.get($colors, 'white');
				}
			}
		}


		@include media-breakpoint-down(sm) {
			&:first-child {
				border-bottom: 1px solid color.scale(map.get($colors, 'grey-regular'), $lightness: 30%);
			}
			&:last-child {
				height: 0;
				padding: 0;
				margin: 0;
				overflow: hidden;
			}
			.link {
				padding: 20px 0 20px 50px !important;
				font-family: $font-bold;
				@include font-size(18);
				.sprite {
					top: 25px;
					left: 25px;
				}
			}
		}

	}

	.link {
		color: map.get($colors, 'black');
		display: block;
		line-height: 1.2em;
		padding: 10px 30px 30px 35px;
		position: relative;
		@include font-size(18);
		@include transition(all, 0.2s, ease-in-out);

		em {
			color: map.get($colors, 'grey-regular');
		}

		.sprite {
			left: 7.5px;
			position: absolute;
			top: 15px;
		}

		&:focus,
		&:hover {
			color: map.get($colors, 'grey-regular');
		}

		&.active {}

		@include media-breakpoint-down(lg) {
			@include font-size(15);
			padding: 10px 30px 20px 35px;
		}
	}

	.link-button {
		@extend .button-regular;
		color: map.get($colors, 'black');
		padding: 3px 15px 3px 12.5px;
		@include font-size(16);

		&:hover,
		&:focus {
			.sprite {
				background-position: -430px 0;
			}
		}

		> * {
			vertical-align: middle;
		}

		@include media-breakpoint-down(md) {
			position: absolute;
			top: 30px;
			right: 55px;
		}
		@include media-breakpoint-down(sm) {
			padding: 3px;
			top: -65px;
			right: 90px;

			span:last-child {
				display: none;
			}
		}

	}

	.navigation-sub {

		.title {

		}

		.item-sub {}

		.link-sub {
			display: block;
			font-family: $font-light;
			line-height: 1.3em;
			margin-top: 5px;
			position: relative;
			@include font-size(16);

			&:before {
				content:"";
				position: absolute;
				left: -35px;
				opacity: 0;
				top: 2px;
				@extend .sprite;
				@extend .arrow-more-white;
				@include transition(opacity, 0.2s, ease-in-out);
				@include media-breakpoint-down(sm) {
					transform: scale(.7) translateX(10px);
				}
			}


			&:focus,
			&:hover {
				color: map.get($colors, 'blue-xlight');
				&:before {
					opacity: 1;
				}
			}

			&.active {}

		}
	}

	@include media-breakpoint-down(sm) {
		background-color: map.get($colors, 'grey-light');
		display: none;
		position: relative;
		padding: 0;
		margin-left: -15px;
		width: calc(100% + 30px);
		border-top: 1px solid color.scale(map.get($colors, 'grey-regular'), $lightness: 30%);
		border-bottom: 1px solid color.scale(map.get($colors, 'grey-regular'), $lightness: 30%);

		&:after,
		&:before {
			content:"";
			background-color: map.get($colors, 'grey-light');
			height: 100%;
			position: absolute;
			top: 0;
			width: 15px;
		}

		&:after {
			left: -15px;
		}
		&:before {
			right: -15px;
		}

		.item {
			margin-left: 0 !important;
			.link {
				padding-bottom: 5px;
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	#navigation-main {
		display: flex !important;
	}

	#button--mobile {
		display: none;
	}
}

.navigation-sub {
	background-color: map.get($colors, 'white');
	display: none;
	height: auto;
	left: auto;
	opacity: 1;
	position: relative;
	top: auto;

	&.first-item {
		background-image: url('assets/images/header-menu-bg.png');
		background-repeat: no-repeat;
		background-position: top right;
		@include media-breakpoint-down(sm) {
			background-image: none;
		}
	}
}

li {
	&[data-navsub] {
		&.focus {
			> .navigation-sub {
				display: block;
				height: auto;
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.navigation-sub {
		background-color: map.get($colors, 'blue-light-alpha');
		color: map.get($colors, 'white');
		display: none;
		margin-left: -#{math.div(map.get($container-max-widths, 'xxl'), 2.055)};
		height: 0;
		left: 50%;
		opacity: 0;
		overflow: hidden;
		padding: 15px 80px 30px;
		position: absolute;
		top: 117px;
		width: calc(map.get($container-max-widths, 'xxl') - $grid-gutter-width);
		z-index: 99;
		@include transition(all, 0.1s, ease-in-out);

		@include media-breakpoint-down(xxl) {
			margin-left: -#{math.div(map.get($container-max-widths, 'xl'), 2.055)};
			width: calc(map.get($container-max-widths, 'xl') - $grid-gutter-width);
		}
		@include media-breakpoint-down(xl) {
			margin-left: -#{math.div(map.get($container-max-widths, 'lg'), 2.055)};
			width: calc(map.get($container-max-widths, 'lg') - $grid-gutter-width);
		}
		@include media-breakpoint-down(lg) {
			margin-left: -#{math.div(map.get($container-max-widths, 'md'), 2.055)};
			padding: 15px 40px 30px;
			top: 77px;
			width: calc(map.get($container-max-widths, 'md') - $grid-gutter-width);
		}
		@include media-breakpoint-down(md) {
			margin-left: -50%;
			padding: 15px 40px 30px;
			top: 159px;
			width: 100%;
		}
		@include media-breakpoint-down(sm) {
			height: auto;
			left: auto;
			margin-left: 0;
			opacity: 1;
			padding: 0 30px 15px;
			margin-top: 0;
			position: relative;
			top: auto;
			width: 100%;
		}
	}
}

body {
	&.header-small {
		#header {
			@include media-breakpoint-up(lg) {}
		}
	}
}

#header-page {
	position: relative;

	.background-image {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;
		width: 100%;
		height: 280px;
	}

  .background-y-center {
    background-position-y: center;
  }

  .background-y-bottom {
    background-position-y: bottom;
  }

	@include media-breakpoint-down(md) {
		.background-image {
			height: 200px;
		}
	}
	@include media-breakpoint-down(sm) {
		.background-image {
			height: 150px;
		}
	}
}

.container-inner-page {
	background-color: $white;
	margin: -60px auto 0;
	padding: 60px;
	position: relative;

	.breadcrumb-item {
		a {
			color: map.get($colors, 'grey-dark') !important;
		}
	}

	@include media-breakpoint-down(md) {
		margin: 0;
		padding: 15px 0 0;
	}

}
