.home-section-network {
  position: relative;
  padding-left: 90px;

  .icon-network {
    top: 0;
    left: 0px;
    position: absolute;
    z-index: 2;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0;

    .title-3 {
      position: relative;
      z-index: 3;
    }

    &:after {
      content: "";
      background-color: $white;
      position: absolute;
      top: -1px;
      width: 100%;
      height: 105px;
      right: 0;
    }
  }
}

.button-network {
    align-items: center;
    background-color: $white;
    border: 1px solid map.get($colors, 'yellow');
    border-radius: 30px;
    display: flex;
    padding: 5px 10px;
    @include transition(all, .2s, ease-in-out);
    @include font-size(16);

    .sprite {
        @include transition(all, .1s, ease-in-out);
    }

    &:hover {
        background-color: map.get($colors, 'yellow');
        color: $white;

        .info-alert-yellow {
            @extend .info-alert;
        }
        .info-disturbance-yellow {
            @extend .info-disturbance;
        }
        .info-leak-yellow {
            @extend .info-leak;
        }
    }

    @include media-breakpoint-down(lg) {
        max-width: 350px;
    }

}

.online-steps-link {
  border-radius: 0 0 0 70px;
  display: block;
  overflow: hidden;
  position: relative;
  @include transition(all, .05s, ease-in-out);


  .title {
    left: 0;
    padding: 10px 30px;
    position: absolute;
    text-align: center;
    top: 10px;
    width: 100%;
    @include font-size(18);
    @include transition(all, .2s, ease-in-out);

    @include media-breakpoint-down(lg) {
      @include font-size(24);
    }
    @include media-breakpoint-down(md) {
      @include font-size(20);
    }
    @include media-breakpoint-down(sm) {
      @include font-size(24);
    }
  }

  $blue-alpha: color.adjust(map.get($colors, 'blue-light'), $alpha: -0.1);
  $blue-transparent: color.adjust(map.get($colors, 'blue-light'), $alpha: -1);

  .hover-decoration {
    background-image: url('assets/images/online-step-hover.png');
    background-repeat: no-repeat;
    background-position: top right;
    bottom: 0;
    left: -10px;
    height: 91px;
    opacity: 0;
    position: absolute;
    width: 109px;
    z-index: 10;
    transform: translateX(-20px);
    @include transition(all, .2s, ease-in-out);
  }

  &:after {
    content: "";
    background: -moz-linear-gradient(top, $blue-transparent 0%, $blue-alpha 100%);
    background: -webkit-linear-gradient(top, $blue-transparent 0%, $blue-alpha 100%);
    background: linear-gradient(to bottom, $blue-transparent 0%, $blue-alpha 100%);
    bottom: 0;
    height: 100px;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    @include transition(all, 0.2s, ease-in-out);
  }

  &:before {
    content:"";
    bottom: 15px;
    left: 50%;
    margin-left: -45px;
    opacity: 0;
    position: absolute;
    transform: translateY(-20px);
    z-index: 2;
    @extend .sprite;
    @extend .arrow-large;
    @include transition(all, 0.2s, ease-in-out);
  }

  &:hover {
    border-radius: 0;
    .title {
      color: map.get($colors, 'blue-light');
    }
    .hover-decoration {
      opacity: 1;
      transform: translateX(0px);
    }
    &:after,
    &:before {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

.reporting-link {
  color: $white !important;
  display: block;
  height: 264px;
  padding: 15px 20px 20px 20px;
  position: relative;
  @include transition(all, .1s, ease-in-out);

  &--header {
    height: 50px;
    align-items: center;
    display: flex;

    .title {
      @include font-size(24);
      font-family: $font-bold;
      line-height: 1em;
    }
  }

  .sprite {
    box-shadow: 0 0 5px rgba(0,0,0, .2);
  }

  .content {
    align-items: center;
    display: flex;
    position: relative;
    bottom: 0;
    left: 0;
    height: calc(100% - 70px);
    justify-content: center;
    font-family: $font-bold;
    @include font-size(38);
  }

  &:hover,
  &:focus {
    transform: scale(.95);
  }

  &.background-orange {
    &:after {
      content: "";
      position: absolute;
      bottom: 15px;
      right: 15px;
      @extend .sprite;
    }

    @extend .arrow-more-white;

    .reporting-link--header {
      justify-content: center;
    }
    .sprite {
      display: block;
      margin: 25px auto 0 auto;
      @include media-breakpoint-down(xl) {
        margin-top: 0;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    height: 219px;
    .title {
      @include font-size(20);
    }
  }

  @include media-breakpoint-down(md) {
    height: 249px;
    .title {
      @include font-size(24);
    }
  }

  @include media-breakpoint-down(sm) {
    height: 200px;
  }


}

#espace-sud-home{
    height: 200px;
    width: auto;
    margin:  -21px auto 0 auto;
    display: block;
    @include media-breakpoint-down(sm) {
        margin:  0 auto;
    }
}

.news-wave {
  position: absolute;
  bottom: -254px;
  left: 131px;
  width: 230px;

  @media (max-width: 1400px){
    left: 114px;
  }
  @include media-breakpoint-down(xl) {
    left: 60px;
    bottom: -222px;
  }
  @include media-breakpoint-down(lg) {
    display: none!important;
    visibility: hidden!important; //for safari wave still display
  }
}
